<template>
  <div class="nav">
    <div class="nav_main">
      <div class="logo" @click="goJump">
        <!-- 动态图标 -->
        <img :src="url" />
      </div>
    </div>
    <agreement v-if="agreementStatus"></agreement>
  </div>
</template>

<script>
import { mapState } from "vuex";
import agreement from "../agreement/agreement.vue";
export default {
  components: {
    agreement,
  },
  data() {
    return {
      url: "/v2/static/logo",
    };
  },
  computed: mapState({
    agreementStatus: (state) => state.register.agreementStatus,
  }),
  watch: {
    $route: {
      handler(newV) {
        this.isHome = newV.name === "home";
      },
      immediate: true,
    },
  },
  created() {
    // console.log(sessionStorage.getItem("istips"));
  },
  methods: {
    goJump() {
      const pageBack =
        window.sessionStorage.getItem("pageBack") || this.$jumpUrl;
      window.location.href = pageBack;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./nav.scss";
</style>
