<template>
  <div class="my-divider">
    <el-divider content-position="center">
      <span>{{ text }}</span>
      <span v-if="status">{{ status }}</span>
    </el-divider>
  </div>
</template>

<script>
export default {
  name: "MyDivider",
  props: {
    text: {
      type: String,
      default: "",
    },
    status: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.my-divider {
  ::v-deep .el-divider {
    .el-divider__text {
      font-size: 18px;
      span {
        &:last-of-type {
          color: #1e90ff;
          margin-left: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .my-divider {
    ::v-deep .el-divider {
      .el-divider__text {
        width: 64% !important;
        span {
          &:last-of-type {
            color: #1e90ff;
            margin-left: 0px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
