import request from "@/utils/request";

export function userLogin(data) {
  return request({
    url: "/service/userService.asmx/userLogin",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function cityAlls(params) {
  return request({
    url: "/service/otherservice.asmx/IATA",
    method: "get",
    params,
  });
}
// export function TicketQuery(params) {
//   return request({
//     url: '/service/TicketInquiries.asmx/GetCityForName_v2',
//     method: 'get',
//     params
//   })
// }
// 搜索
// export function TicketQuery(params) {
//   return request({
//     url: '/service/TicketInquiries.asmx/TicketQuery',
//     method: 'get',
//     params
//   })
// }
export function TicketQuery(data) {
  return request({
    url: "/v2/ticket/ticketSearch",
    method: "post",
    data,
  });
}
//注册
export function userRegister(data) {
  return request({
    url: "/service/userService.asmx/userRegisterWithName",
    method: "post",
    data,
  });
}
//验证是否注册过
export function RegisterAccountCheck(data) {
  return request({
    url: "/service/userService.asmx/RegisterAccountCheck",
    method: "post",
    data,
  });
}
// 验证码 弃用
export function PhoneCode(data) {
  return request({
    url: "/v2/PhoneCode",
    method: "post",
    data,
  });
}
// // 1注册 2找回 3订单
// // 获取验证码
// export function getMailCode(data) {
//   return request({
//     url: "/v2/getMailCode",
//     method: "post",
//     data,
//   });
// }
// 找回密码 下一步
export function seekPwd(data) {
  return request({
    url: "/service/userService.asmx/seekPwd_",
    method: "post",
    data,
  });
}
// 重置密码
export function resetting(data) {
  return request({
    url: "/service/userService.asmx/seekPwd",
    method: "post",
    data,
  });
}

// 修改密码
export function userPwd(data) {
  return request({
    url: "/service/userService.asmx/seekPwd",
    method: "post",
    data,
  });
}


// 首页模糊查询
export function GetCityForName(data) {
  return request({
    url: "/service/TicketInquiries.asmx/GetCityForName_v2",
    method: "post",
    data,
  });
}
// 我的行程（已出票订单）
export function GetOrderList(data) {
  return request({
    url: "/service/TicketInquiries.asmx/GetOrderList",
    method: "post",
    data,
  });
}
// 个人信息
export function GetuserInfo(data) {
  return request({
    url: "/service/userService.asmx/userInfo",
    method: "post",
    data,
  });
}
// 修改个人信息
export function updateuserInfo(data) {
  return request({
    url: "/service/userService.asmx/updateUserInfoV2",
    method: "post",
    data,
  });
}

// 修改常用乘客信息
export function upUserContacts(data) {
  return request({
    url: "/service/userService.asmx/upUserContactsV2",
    method: "post",
    data,
  });
}
// 添加常用旅客
export function addUserContacts(data) {
  return request({
    url: "/service/userService.asmx/userContactsV2",
    method: "post",
    data,
  });
}
// 删除常用旅客
export function DeleteContacts(data) {
  return request({
    url: "/service/userService.asmx/DeleteContacts",
    method: "post",
    data,
  });
}
// 验票
export function TicketVerify(data) {
  return request({
    url: "/service/TicketInquiries.asmx/TicketVerify",
    method: "post",
    data,
  });
}
// 生成订单
export function Book(data) {
  return request({
    url: "/service/TicketInquiries.asmx/Book",
    method: "post",
    data,
  });
}
// PayVerify 支付验证
export function PayVerify(data) {
  return request({
    url: "/service/TicketInquiries.asmx/PayVerify",
    method: "post",
    data,
  });
}
// payAll 支付
export function payAll(data) {
  return request({
    url: "/service/TicketInquiries.asmx/payAll",
    method: "post",
    data,
  });
}

// infomation 验价接口
/**
  "adultNumber": 0, // 成人人数
  "callback": "string",
  "childNumber": 0, // 儿童人数
  "currency": "string",
  "data": "string", // data唯一标识
  "infantNumber": 0,
  "requesttype": "string",
  "tripType": 0
 */
export function getTicketVerify(data) {
  return request({
    url: "/v2/ticket/ticketVerify",
    method: "post",
    data,
  });
}

// 生单接口
export function setTicketBooking(data) {
  return request({
    url: "/v2/ticket/ticketBooking",
    method: "post",
    data,
  });
}
// pay 支付
export function setTicketPay(data) {
  return request({
    url: "/v2/ticket/ticketPay",
    method: "post",
    data,
  });
}
// 获取用户支付类型
export function getB2bType(params) {
  return request({
    url: "/v2/user/getB2bType",
    method: "get",
    params,
  });
}
// 订单详情
export function backOrderGetDetails(data) {
  return request({
    url: "/v2/order/commonQuery",
    method: "post",
    data,
  });
}

// 1注册 2找回 3订单
// 获取验证码
export function getMailCode(data) {
  return request({
    url: "/v2/getMailCode",
    method: "post",
    data,
  });
}

// 登录
export function login(data) {
  return request({
    url: "/v2/login",
    method: "post",
    data,
  });
}

// 退出
export function logout(data) {
  return request({
    url: "/v2/logout",
    method: "post",
    data,
  });
}

// 注册
export function register(data) {
  return request({
    url: "/v2/register",
    method: "post",
    data,
  });
}

// 重置密码
export function resetPassword(data) {
  return request({
    url: "/v2/resetPassword",
    method: "post",
    data,
  });
}

// 获取用户信息
export function getInfo(data) {
  return request({
    url: "/v2/user/getInfo",
    method: "get",
    data,
  });
}

// 修改用户信息
export function setInfo(data) {
  return request({
    url: "/v2/user/updateInfo",
    method: "post",
    data,
  });
}

// 获取旅客列表
export function getUserPassengers(data) {
  return request({
    url: "/v2/user/getUserPassengers",
    method: "get",
    data,
  });
}

// 更新或者添加旅客信息
export function updateUserPassenger(data) {
  return request({
    url: "/v2/user/updateUserPassenger",
    method: "post",
    data,
  });
}
// 联系人列表
export function getContacts(data) {
  return request({
    url: "/v2/user/getContacts",
    method: "get",
    data,
  });
}
// 添加更新联系人
export function updateUserContact(data) {
  return request({
    url: "/v2/user/updateUserContact",
    method: "post",
    data,
  });
}
// 删除联系人
export function delUserContact(data) {
  return request({
    url: "/v2/user/delUserContact",
    method: "post",
    data,
  });
}

// 删除常旅客信息
export function delUserPassenger(data) {
  return request({
    url: "/v2/user/delUserPassenger",
    method: "post",
    data,
  });
}
// 存储
export function saveParams(data) {
  return request({
    url: "/v2/searchParams/saveParams",
    method: "post",
    data,
  });
}
// 获取存储
export function getParams(params) {
  return request({
    url: "/v2/searchParams/getParams",
    method: "get",
    params,
  });
}
// 获取公共信息
export function siteInfo(params) {
  return request({
    url: "/v2/static/siteInfo",
    method: "get",
    params,
  });
}

// 获取logo
export function getLogo(params) {
  return request({
    url: "/v2/static/logo",
    method: "get",
    params,
  });
}

// 获取ico
export function getFavicon(params) {
  return request({
    url: "v2/static/favicon",
    method: "get",
    params,
  });
}

// 获取联系人 乘客
export function GetCommon(data) {
  return request({
    url: "/v2/public/common",
    method: "post",
    data,
  });
}

