<template>
  <div id="app">
    <Nav v-if="this.$route.path !== '/loading'"></Nav>
    <router-view :key="$route.fullPath" />
    <el-backtop :bottom="100"> </el-backtop>
  </div>
</template>
<script>
import Nav from "./components/nav/nav";
import { siteInfo } from "@/api/index";
export default {
  components: {
    Nav,
  },
  data() {
    return {};
  },
  created() {
    this.thePublicInformation();
    this.getIco();
  },
  methods: {
    //获取公共信息
    thePublicInformation() {
      siteInfo().then((res) => {
        const { data, code } = res.data;
        if (code == 200) {
          data["logo"] = `${
            process.env.NODE_ENV === "development" ? "/api" : ""
          }/v2/static/logo`;
          this.$store.dispatch("common/set_PageConfig", data);
          document.title = data.site_title;
        }
      });
    },
    // 获取ico
    getIco() {
      document.querySelector("link[rel=icon]").href = `${
        process.env.NODE_ENV === "development" ? "/api" : ""
      }/v2/static/favicon`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/mglobalSelect.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  // background-color: #ecf0f1;
  flex: 1;
  // background-color: #ecf0f1;

  .wrap {
    display: flex;
    justify-content: space-around;
  }
}
div.el-tooltip__popper.is-dark {
  font-size: 0.24rem;
}
nav {
  // padding: 30px;
  a {
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.el-message-box__wrapper {
  .el-message-box {
    width: 7rem !important;
    .el-message-box__header {
      background-color: #e7e7e7;
      .el-message-box__title {
        font-size: 0.28rem;
      }
    }

    .el-message-box__message {
      font-size: 0.24rem;

      padding: 0.2rem 0;
      > p {
        line-height: 0.5rem;
      }
    }
    .el-button--primary {
      font-size: 0.24rem;
    }
  }
  .tzbtn {
    background-color: red !important;
    border: none;
    &:hover {
      background-color: red !important;
    }
  }
}
//搜索框 子级选项样式
.childItem {
  font-size: 0.3rem !important  ;
}
.infoDatePicker {
  width: 400px !important;
  margin-bottom: 10px;
  &.el-picker-panel {
    width: 400px;
    color: #000 !important;
    font-weight: 600 !important;

    .el-picker-panel__content {
      width: 94%;
    }
  }
  .el-picker-panel__content {
    width: auto;
  }
  .el-date-picker__header {
    margin: 0.3rem 0.2rem;
    text-align: center;
    span {
      font-size: 26px;
    }

    .el-picker-panel__icon-btn {
      font-size: 24px !important;
      font-weight: 700;
      margin-top: 0;
    }
  }
  .el-date-table__row {
    span {
      width: 0.5rem;
      height: 0.5rem;
      line-height: 0.5rem;
    }

    .available {
      font-size: 24px;
    }
  }
  tr {
    font-size: 24px;
    font-weight: 600 !important;
    color: #000;
  }
  td {
    height: 0.8rem;
  }
}
.el-select-dropdown {
  .el-select-dropdown__wrap {
    .el-select-dropdown__item {
      font-size: 24px;
      color: #000;
      // font-weight: 600;
    }
  }
}
.el-picker-panel.el-date-picker {
  .el-date-picker__header {
    margin: 0.3rem 0.2rem;
    text-align: center;

    span {
      font-size: 0.28rem;
    }

    .el-picker-panel__icon-btn {
      font-size: 0.28rem !important;
      font-weight: 700;
      margin-top: 0;
      &[aria-label*="Year"] {
        display: none;
      }
    }
  }

  width: 5rem !important;
  .el-date-table {
    font-size: 0.28rem;
  }
  .el-picker-panel__content {
    width: 4.6rem !important;
  }
  td {
    div {
      height: 0.5rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .infoDatePicker {
    width: 80% !important;
  }
}
</style>
