<template>
  <el-dialog
    :title="title"
    center
    custom-class="agreement-form"
    :visible.sync="agreementStatus"
    :before-close="beforeClose"
  >
    <!-- <p class="title">Legal Notices</p> -->
    <p style="text-align: justify">
      INTRODUCTION<br /><br />

      Aoflights.com with its Australia office address at Advance Olympic
      International Pty Ltd ( ABN 88052894845) 10 Captain Cook Drive, Kurnell,
      NSW 2231 is a website and mobile application (“Website”) operated by AO
      Travel. To contact us, please email or telephone our customer service
      team. Our email address and helpline number, please find aoflight.com
      contact us.<br /><br />

      ABOUT THESE TERMS
      <br /><br />
      Please read these Terms and Conditions carefully. You must not make any
      booking unless you are 18 years of age or older and understand and agree
      with the following Terms and Conditions. <br />
      These Terms and Conditions apply to all Bookings you make with AO Travel
      (Aoflights.com). These Terms and Conditions govern our relationship with
      you. By using our booking and other services you acknowledge that you have
      read and understood, and agree to be bound by these terms.<br /><br />

      When you book a Travel Product, your booking is also subject to the
      relevant Supplier’s terms and conditions (for e.g., the airline’s terms
      and conditions). It is important that you read these, as they form a
      separate legal agreement between you and the relevant Supplier. There is
      more information on this in each of the Travel Product sections below. If
      there are any inconsistencies between the Supplier’s term and conditions
      and these terms apply. Different terms and conditions (such as
      cancellation and change flights) apply to different Travel Products, these
      will be made available to you during your booking process and form part of
      these terms. You should read them carefully before booking.<br />

      <br /><br />
      TRAVEL REQUIREMENTS
      <br /><br />

      1. Passports <br />

      (a) All departing passengers require a valid passport for international
      travel. This means the passport must be valid for a set minimum period
      beyond your travel dates (the required minimum period depends on the
      country you visit).<br />
      (b) Please ensure you have a valid Passport well in advance of travel as
      either the airline staff or immigration authorities will not allow you to
      pass through customs and board your flight without this documentation.<br />
      (c) The Australian Department of Foreign Affairs and Trade provides
      helpful information on applying for or renewing an Australian passport.<br /><br />

      2. Visas <br />
      (a) A visa provides you with the authority as a non-citizen to travel to
      another country. Some countries will not allow you to enter without a
      visa, or in some cases, without proof of a return or onward electronic or
      paper ticket.<br />
      (b) Please ensure you have a valid Visa (as applicable) for all the
      countries you are travelling to which require a visa well in advance of
      travel as either the airline staff or immigration authorities will not
      allow you to pass through customs and board your flight without this
      documentation.<br />
      (c) Visas are obtained from the issuing authority in Australia, usually an
      embassy. Visa requirements vary by both country you are travelling to and
      the current citizenship you hold so please ensure you check well in
      advance of your travel dates to allow sufficient processing time as
      required by the embassy. If you have booked a one-way ticket please ensure
      you have checked with the Consulate of the country you are travelling to
      in order to ensure that you comply with their laws and regulations.<br />
      (d) The South African Immigration Act effective 1 June 2015 will require
      all customers under 18 years old travelling to and from South Africa to be
      in possession of an unabridged birth certificate. Parent/s travelling with
      children under 18 years old will be asked to produce the required
      documentation at check-in for each flight. <br />
      <br />
      3. General<br />
      (a) We recommend before travelling that you check the Government website
      relating to the countries you are travelling to for any applicable travel
      advisories relevant to those countries (for example, the relevant New
      Zealand website is located at www.safetravel.govt.nz and the relevant
      Australian website is located at www.dfat.gov.au/travel).<br />
      (b) As well, please note that Government legislation prohibits the
      carriage of dangerous goods on aircraft. To view what these are and what
      is permissible to carry please go to
      http://www.casa.gov.au/operations/standard-page/dangerous-goods.<br />

      <br /><br />
      OUR SERVICES<br /><br />
      1. Agency<br />
      (a) We provide a travel booking service that enables you to:<br />
      (b) book or purchase travel related goods and services promoted on our
      Website<br />
      (c) conduct flight and other travel related searches and reservations<br />
      (d) access our Customer Service assistance service<br />
      (e) use our payment processing, documentation and ticketing services.<br />
      (f) When Booking or purchasing a Travel Product we act on behalf of the
      Supplier to accept your Booking and payment. Each booking you make forms a
      contract between you and the applicable Supplier and is subject to the
      Product Terms, including conditions of carriage and limitations of
      liability, imposed by the Supplier. We recommend that you read the Product
      Terms before finalising your booking. We can provide you with copies of
      the relevant terms and conditions on request.<br />
      (g) We do not act as your agent when you book Travel Products through our
      Website.<br />
      (h) Your legal rights and remedies in connection with the provision of the
      Travel Products you book are against the Supplier and, except to the
      extent a problem is directly and primarily caused by fault on our part,
      are not against us. Specifically, if for any reason (excluding fault on
      our part) any Supplier is unable to provide the Travel Product for which
      you have contracted either at all, or to the requisite standard, your
      remedies are against that Supplier and not against us. However, our
      Customer Service team will assist you should you have an issue with a
      Supplier or the Travel Products and services they provide to you.<br />
      <br />
      2. Customer Service<br />
      When you make a flight Booking through Aoflights.com, the Servicing Fee
      paid as part of the Booking provides you with access to our global
      Customer Service offering. The Customer Service provided includes access
      to staff either online, by email or by phone to confirm your Booking, make
      any changes to your Booking or otherwise seek assistance with your
      Booking. The Customer Service provided by AO flight is global in nature
      and can be accessed from anywhere around the world.<br />
      <br />

      3. Travel Products<br />
      3.1 Product Terms<br />
      In addition to these terms, Suppliers and hotels may impose (and you must
      comply with) their own terms and conditions in relation to their Travel
      Products (Product Terms) (for example, an airline's terms of carriage, the
      cancellation conditions of a hotel booking). You must accept and agree to
      those Product Terms to complete your Booking. You should carefully read
      the Product Terms specific to the applicable Travel Products before
      completing your Booking.<br />
      <br />
      3.2 Flights<br />
      Flight fulfilment is provided by the airline(s) selected at the time of
      finalising the Booking. For your air travel, you are bound by the Product
      Terms of the selected airline(s). Where your booking comprises air travel
      to be provided by a mix of different airlines each with different Product
      Terms, the most stringent of the Product Terms shall apply to all air
      travel segments within your booking (for example, if the Product Terms for
      one segment of your air travel provides that the fare is refundable, but
      the Product Terms for another segment of your air travel on the same
      booking provides that the fare is non-refundable, all flights within that
      booking shall be non-refundable).<br />
      <br />
      Aoflights.com is not responsible for subsequent changes to airline
      scheduled departure times, departure dates or flight cancellations.<br />
      <br />
      3.3 Commission <br />
      You acknowledge and agree that Ao flights.com may receive a commission or
      an affiliate fee or a marketing fee from Bookings and other transactions
      entered into by you using the Services <br />
      <br />
      4. Your Obligations <br />
      (a) You must only access and use the Services<br />
      (b) to make enquiries about and Bookings for Travel Products on the
      aoflights.com (Permitted Purpose)<br />
      (c) in accordance with these terms<br />
      • have sufficient credit on your credit card account to meet all charges
      for Travel Products you book through the Booking facility<br />
      • keep secure any means of identification that Aoflights.com provides to
      you in relation to your Account or specific booking<br />
      • comply with all requirements for the Travel Product you've booked,
      including any Product Terms (where applicable) and<br />
      • ensure that you have the necessary documentation to travel.<br />
      (d) You must not:<br />
      (e) access or use the Services:<br />
      • for any activities which breach any laws, infringe any third party's
      rights, or breach any standards, content requirements or codes promulgated
      by any relevant authority<br />
      • in any way which interferes with other users or defames, harasses or
      menaces anyone<br />
      • for any purpose (commercial or otherwise) other than the Permitted
      Purpose without the express written permission of aoflights.com, including
      any reproduction, data mining, automated querying or similar data
      extraction processing<br />
      (f) reverse or cancel a payment without aoflights.com prior written
      consent<br />
      (g) resell the Services, Travel Products or any other products and
      services accessed by means of the Services or permit any other person to
      use your user identification to access the Services, or grant a third
      party the right to access and use the Services.<br />
      (h) You are solely responsible for:<br />
      (i) assessing the suitability of any Travel Products for your needs and<br />
      (j) all matters relating to complying with the laws and regulations of all
      immigration authorities of the countries you travel to and from.<br />
      (k) When you make Bookings on behalf of any other person you must have
      their authority to make the Booking and to bind them to these Terms and
      Conditions and all relevant Product Terms. We will rely on your authority
      to do this.<br />
      <br />
      5. Your Account <br />
      (a) To use Ao flights.com booking facility you may be required to create
      an online account (Account) and/or provide:<br />
      (i) personal information, including (in some cases) passport details<br />
      (ii) billing information;<br />
      (iii) any other information reasonably required by aoflights.com to
      complete your Booking and<br />
      (iv) an Account password.<br />
      (b) You are responsible for all use of the Services, and all transactions
      entered into by means of the Services, under your Account. Aoflights.com
      takes no responsibility for any transactions which may occur as a result
      of a disclosure by you of your password to third parties, or through any
      unauthorised access to your personal information unless caused directly
      and primarily through a fault on our part.<br />
      (c) Except to the extent caused by our fraud, negligence or wilful
      neglect, you indemnify Aoflights.com from and against all actions, claims,
      suits, demands, liabilities, costs or expenses arising out of or in any
      way connected to use of the Services by you or any other person using your
      membership identification, Account or password.<br />
      <br />
      6. Intellectual Property <br />
      (a) All the intellectual property rights in the Aoflights.com and the
      Booking Facility are owned by AO TRAVEL and/or its licensors (as
      applicable). The only rights you have to this intellectual property are
      the limited rights to use and access the Website and the Booking Facility
      for the Permitted Purpose.<br />
      (b) You must not:<br />
      (i) disassemble, decompile, reverse engineer the whole or any part of the
      software accessed by means of the Services; or<br />
      (ii) modify, copy, transmit, display, perform, publish, license or create
      derivative works from any information or software accessed by means of the
      Services.<br />
      <br />
      7. Your Information <br />
      AO flights.com will handle and may use and disclose any personal
      information collected by Aoflights.com about you in accordance with Ao
      flight’s privacy policy, available at http://www.aoflights.com
      about/privacy/. Aoflights .com may also provide such personal information
      to its related entities and to Suppliers as is required to complete your
      Booking. You accept that the recipients of this disclosure may not have in
      place privacy policies that are equivalent to Aoflights.com. <br />
      <br />
      8. Payments and Refunds<br />
      Payment<br />
      (a) Bookings must be paid in full in order to purchase the Travel
      Product(s).<br />
      (b) By clicking “Pay Now”, you accept:<br />
      (i) the fees and charges payable to Aoflights.com and its related
      entities, set out in the payment summary, and<br />
      (ii) the Supplier's offer to sell the Travel Products to you for the price
      set out in your shopping cart, and subject to these Terms and Conditions
      and any applicable Product Terms.<br />
      (iii) consent to Aoflights.com supplying your billing, payment and
      identification information (including personal information) to those
      Suppliers and/or hotels who will supply the Travel Products and accept
      that the recipients of this disclosure may not have in place privacy
      policies equivalent to AO flgihts.com<br />
      (c) Your Booking is not confirmed until full payment is received by
      Aoflights.com and confirmation is provided to you by Aoflights.com or the
      Supplier(s) with a confirmation or booking number for each element of the
      Travel Product(s) booked.<br />
      (d) Payments received from you for a Booking are held on trust for the
      relevant Suppliers and paid to the Supplier once the Supplier confirms the
      Booking. Once paid to the Supplier, Aoflights.com is unable to refund your
      Booking payment unless the Supplier refunds the payment to us. <br />
      <br />
      9. Transaction Verification<br />
      (a) You:<br />
      (i) authorise Aoflights.com agents to:<br />
      • charge all fees incurred to the credit card designated by you<br />
      • disclose your credit card details or other payment credentials to, and
      obtain information from, any financial institution or card issuer, payment
      provider to verify the credit card, payment credentials and identification
      details that you provide.<br />
      • take steps to confirm that there is sufficient credit on your credit
      card account or other payment account to meet likely charges<br />
      (ii) authorise your credit card issuer, payment provider or financial
      institution to verify that your payment credentials (for example, your
      credit card and identification details) you have provided to Aoflights.com
      are accurate and<br />
      (iii) consent to Aoflgihts.com supplying your billing, payment and
      identification information (including personal information) to those
      Suppliers and/or hotels who will supply the Travel Products in your
      Booking to you.<br />
      (b) Before aoflights.com proceeds to process any Booking on your behalf or
      issues your travel documentation, various checks are taking place to
      validate the integrity of your payment credentials, including any credit
      card or account information and in certain circumstances you will be
      required to submit verification documentation to substantiate identity.
      This process is intended to reduce the potential risk for fraud and
      disruption to passengers upon check in.<br />
      (c) If your credit card or other payment option is not approved
      aoflights.com may attempt to contact you. Where aoflights.com cannot
      contact you and the ticket issuance period lapses (thereby making the
      Booking invalid), Aoflights.com will have no choice but to cancel the
      booked arrangements. Under these circumstances aoflights.com accepts no
      responsibility for any re-Booking costs unless the loss was caused
      directly and primarily by fault on our part.<br />
      (d) aoflights.com reserves the right to decline Bookings where in its
      reasonable opinion it is appropriate to do so. Any automatic Booking
      confirmations are conditional on aoflights.com acceptance of any financial
      risks, potential ticket despatch delays, or other regulations that may
      apply. <br />
      (e) If payment is not received for your confirmed Booking for any reason,
      you agree to pay aoflights.com all amounts due on demand.<br />
      <br />
      10. Refunds<br />
      (a) Your right to a refund of an airfare booked on the is governed by the
      terms and conditions of the airfare you purchased and is as determined by
      the applicable airlines. Many fares are non-refundable. Airline charges or
      cancellation fees may apply to your particular airfare.<br />
      (b) Your right to a refund of a travel product booked on the website is
      governed by the terms and conditions of the supplier of the travel product
      (Supplier). You should read the Supplier’s refund policy carefully. Many
      travel products are non-refundable and Supplier charges or cancellation
      fees may apply to your particular travel product.<br />
      (c) aoflights.com will only be liable to provide a refund to you to the
      extent that it actually receives a refund from the relevant Supplier.
      Where refunds are due to you from a Supplier, aoflgihts.com will provide
      reasonable assistance to you in claiming any refunds from that Supplier.
      In that situation, aoflights.com is authorised (but not obliged) to seek
      such refunds from the Supplier directly and on its own behalf.<br />
      (d) Where a refund is applicable, the turnaround time from the date of
      your request for refund to the date you are provided with your refund is
      beyond the control of aoflights.com. aoflights.com will act diligently to
      pursue your claim for a refund from a Supplier and promptly remit any
      refund received from a Supplier in a timely manner once it has been
      cleared by our bank. <br />
      <br />
      11. Changes and Cancellations<br />
      General<br />
      (a) Because aoflights.com is reliant on the availability of Travel
      Products from Suppliers, aoflights.com reserves the right to amend your
      Booking at any time in consultation with you if:<br />
      (i) an error is made by the Supplier or hotel in relation to a Travel
      Product or<br />
      (ii) in circumstances beyond aoflights reasonable control, the Supplier or
      hotel advises that it is unable to provide the Travel Product at the price
      advertised on the Website.<br />
      In such circumstances additional charges may have to be paid by you for
      the Travel Products and/or your itinerary may need to be changed.<br />
      Information about how to change or cancel your Booking is available on the
      Website at: http://www.aoflights.com.au/about/change-my-booking.If you are
      requesting a change or cancellation via our website or directly with our
      telephone or messaging team, there is no additional aoflights.com fee for
      this service.<br />
      <br />
      (b) If you cancel any Travel Product (including any element of a Package)
      you may incur cancellation charges from the Supplier supplying the
      cancelled Travel Product and you agree to indemnify aoflights.com (and any
      related parties) for any fees or charges incurred by them in relation to
      your cancellation, except to the extent the cancellation was directly and
      primarily caused by our fault.<br />
      <br />
      12. Fees<br />
      The following fees (Fees) may apply to your Booking:<br />
      12.1 Payment Fee<br />
      may charge you a Payment Fee in relation to processing your booking and
      accepting payment.<br />
      Payment Fees are charged by aoflights.com in its own capacity (as
      merchant), and not as an agent for any other party. Payment Fees cover a
      range of costs, services, fees and charges in relation to your booking,
      including (among other things for example fraud prevention) the reasonable
      costs of accepting card payments. Payment Fees will be shown in the price
      of your Booking on the checkout page after a payment method has been
      selected.<br />
      <br />
      12.2 Servicing Fee <br />
      The Servicing Fee included in your Booking payment summary is
      non-refundable and will be charged to you within 24 hours. Funds need to
      be available on your card at the time of making the booking otherwise
      ticketing will not take place and you may need to rebook at a higher fare
      level.<br />
      The Servicing Fee provides you access to our global Customer Service
      function. The Customer Service provided to you by aoflights.com is global
      in nature and can be accessed from anywhere around the world.<br />
      <br />
      The Servicing Fee covers a range of costs, services, fees and charges in
      relation to providing you with Customer Service in relation to your
      Booking and includes GST. Aoflgihts.com provides customers access to 24/7
      support channels including telephone, live chat, email and frequently
      asked questions. Included in the Servicing Fee is the ability to change
      Bookings online. Customers will just have to pay any applicable airline
      fees. Furthermore, the Servicing Fee provides customers with assistance
      when an airline initiates a schedule change, or when a carrier does not
      immediately confirm a Booking.<br />
      <br />
      No refund of the Servicing Fee is given if you do not request Customer
      Service in relation to your Booking.<br />
      <br />
      You will be charged the following Servicing Fee in relation each
      applicable Booking:<br />
      (i) Domestic $20.00<br />
      (ii) NZ/Pacific $25.00<br />
      (iii) International $30.00<br />
      <br />
      12.3 Cancellation fees<br />
      Aoflgihts.com reserves the right to charge amendment or cancellation fees
      depending on circumstances and any relevant Product Terms.<br />
      <br />
      13. Pricing<br />
      13.1 Pricing<br />
      (a) All pricing is reflected in Australian Dollars (AUD) unless otherwise
      specifically denoted on the screen.<br />
      (b) aoflights.com makes no representation that its airline price sales are
      identical to prices on individual airline sites that may exist from time
      to time.<br />
      (c) aoflgihts.com reserves the right for marketing and commercial reasons
      to modify prices.<br />
      (d) The Servicing Fee for bookings and Insurance (if selected) are
      non-refundable.<br />

      <br />
      13.2 Currency Variations<br />
      For some international mix-and-match flights aoflights.com flights results
      page indicates that some return flight pricing has been converted to AUD
      at the exchange rate on the day of booking. Variations to your final price
      may occur due to currency fluctuations. Please note, where any pricing has
      been converted from a foreign currency to AUD your credit card company or
      other payment provider may charge a conversion fee.<br />
      <br />
      13.3 GST for non-residents booking domestic flights in Australia<br />
      GST is still applicable in circumstances where your domestic ticket is
      booked and ticketed separately to the international portion of your ticket
      or where it is subsequently separately booked and ticketed, the domestic
      Booking is not cross referenced to the international Booking.<br />

      14. Liability<br />
      14.1 Liability<br />
      (a) To the extent permitted by law, we do not accept any liability in
      contract, tort or otherwise for any injury, damage, loss (including
      consequential loss), delay, additional expense or inconvenience caused
      directly or indirectly by the acts, omissions or default, whether
      negligent or otherwise, of Suppliers and other third party providers over
      whom we have no direct control, an event of Force Majeure affecting you,
      us or a Supplier or any other event which is beyond our control or which
      is not preventable by reasonable diligence on our part. Under
      circumstances where our liability cannot be excluded and where liability
      may be lawfully limited, such liability is limited to the remedies
      required of us under applicable law (including the Australian Consumer
      Law). In particular, we disclaim any liability for any consequential loss,
      including loss of enjoyment or amenity. This liability clause is subject
      to your rights under the Australian Consumer Law and nothing in these
      terms and conditions is intended to limit any rights you may have under
      the Competition and Consumer Act 2010 (Cth).<br />
      (b) Without limiting the disclaimer of liability in the previous
      paragraph, any obligation we have to you will be suspended during the time
      and to the extent that we are prevented from, or delayed in, complying
      with that obligation by an event of Force Majeure.<br />
      (c) Your rights with respect to a confirmed booking affected by an event
      of Force Majeure will be subject to the terms and conditions of the
      relevant Supplier.<br />
      (d) If any guarantees or warranties are implied by law that cannot be
      excluded, then to the maximum extent permitted by law our liability for
      breach of such warranties is limited to, at our option:<br />
      (i) in the case of products<br />
      (A) the replacement of the products or the supply of equivalent
      products<br />
      (B) the payment of the cost of replacing the products or acquiring
      equivalent products<br />
      (ii) in the case of services:<br />
      (A) the supply of the services again<br />
      (B) the payment of the cost of having the services supplied again.<br />
      (e) Your airlines liability to you and your claim against an airline may
      also be limited to the extent that any relevant international conventions,
      for example the Montreal Convention in respect of travel by air, the
      Athens Convention in respect of travel by sea, the Berne Convention in
      respect of travel by rail and the Paris Convention in respect of the
      provision of accommodation, which may limit the amount of compensation
      which can be claimed for death, injury, or delay to passengers and loss,
      damage and delay to luggage.<br />
      <br />
      14.2 Indemnification <br />
      Except to the extent caused by our fraud, negligence or wilful neglect,
      you agree to indemnify aoflights.com and any of its officers, directors,
      employees and agents from and against any claims, causes of action,
      demands, recoveries, losses, damages, fines, penalties or other costs or
      expenses of any kind or nature including but not limited to reasonable
      legal and accounting fees, brought by any third parties as a result of:<br />
      (a) your breach of these Terms and Conditions or the documents referenced
      herein<br />
      (b) your breach of any law or the rights of a third party; or <br />
      (c) your use of this Website.<br />

      <br />
      15. Website<br />
      15.1 Information on the Website<br />
      (a) Some of the material accessible through the Website is provided by
      Suppliers and other advertisers. This material does not form part of our
      Services, and we are not responsible for this material. Aoflights.com does
      not warrant, and disclaims any liability for the accuracy or completeness
      of the information provided by Suppliers and other advertisers in relation
      to their Travel Products and other goods and services that they
      provide.<br />
      (b) Information provided through the Website is provided in good faith,
      and is subject to amendment at any time without notice.<br />
      (c) You should make your own evaluation of the accuracy or completeness of
      any information, opinion, advice or other content available through the
      Website.<br />
      (d) The Website does not include or provide access to every Travel Product
      available in the market.<br />
      (e) aoflights.com makes no representation that its prices or products are
      identical to prices or products on other Travel Product sites that may
      exist from time to time. Aoflights.com reserves the right for marketing
      and commercial reasons to modify prices and products.<br />
      <br />
      16. General<br />
      16.1 Changes to these Terms <br />
      Aoflights.com may, when it considers it reasonably appropriate to do so in
      the relevant circumstances, amend these Terms and Conditions at any time
      by posting the revised version of the agreement to the Website. The
      amended agreement will take effect from the time of posting on the
      Website.<br />
      <br />
      16.2 Assignment <br />
      You must not novate or assign any of your rights under these Terms and
      Conditions to a third party.<br />
      <br />
      16.3 Governing Law <br />
      These terms are governed by the laws in force in NSW, Australia. You
      irrevocably submit to the non-exclusive jurisdiction of the courts of NSW
      and courts of appeal from them for determining any dispute concerning
      these terms.<br />
      <br />
      COMPLAINTS<br />
      If you have any questions or complaints about aoflights.com website or
      booking services, Please don’t hesitate us.<br />
    </p>
  </el-dialog>
</template>

<script>
export default {
  name: "agreement",
  data() {
    return {
      title: this.$t("m.contentTitle"),
    };
  },
  computed: {
    agreementStatus() {
      return this.$store.state.register.agreementStatus;
    },
  },
  methods: {
    beforeClose() {
      this.$store.dispatch("register/set_agreementStatus", false);
    },
  },
};
</script>

<style lang="scss">
.agreement-form.el-dialog {
  width: 32%;
  height: 68%;
  margin-top: 3rem !important;
  overflow: hidden;
  @extend %f-d-c;
  .el-dialog__body {
    flex: 1;
    overflow: auto;
    padding: 0rem 0.4rem;
  }
  .el-dialog__title {
    font-size: 32px;
    font-weight: 600;
  }
  p {
    font-size: 22px;
  }
}
@media screen and (max-width: 1680px) {
  .el-dialog.agreement-form {
    width: 42%;
  }
}

@media screen and (max-width: 1200px) {
  .el-dialog.agreement-form {
    width: 50%;
  }
}
@media screen and (max-width: 960px) {
  .el-dialog.agreement-form {
    width: 40%;
  }
}

@media screen and (max-width: 766px) {
  .el-dialog.agreement-form {
    width: 80%;
  }
}
</style>
